@use '@angular/material' as mat;

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'abstracts/default';



// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.legacy-core();
@include mat.core();


@font-face {
  font-family: 'Gilroy Medium';
  src: url('../fonts/gilroy-medium-webfont.woff') format('opentype');
}

@font-face {
  font-family: 'Gilroy Semi Bold';
  src: url('../fonts/gilroy-semibold-webfont.woff') format('opentype');
}

@font-face {
  font-family: 'Gilroy Bold';
  src: url('../fonts/gilroy-bold-webfont.woff') format('opentype');
}

$omnia-purple-palette: (
  50: #f3e5f5,
  100: #e2dff5,
  200: #ce93d8,
  300: #ba68c8,
  400: #ab47bc,
  500: #9c27b0,
  600: #7c29a6,
  700: #7b1fa2,
  800: #6a1b9a,
  900: #3b1343,
  A100: #ea80fc,
  A200: #e040fb,
  A400: #d500f9,
  A700: #aa00ff,
  contrast: (50: $primary-color,
    100: $primary-color,
    200: $primary-color,
    300: $primary-inv-color,
    400: $primary-inv-color,
    500: $primary-inv-color,
    600: $primary-inv-color,
    700: $primary-inv-color,
    800: $primary-inv-color,
    900: $primary-inv-color,
    A100: $primary-color,
    A200: $primary-inv-color,
    A400: $primary-inv-color,
    A700: $primary-inv-color,
  ),
);

$omnia-typography: mat.define-typography-config($headline-6: mat.define-typography-level($font-size: 18px,
      $line-height: 22px,
      $font-weight: bold,
      $font-family: 'Gilroy Medium',
    ),
    $headline-5: mat.define-typography-level($font-size: 22px,
      $line-height: 28px,
      $font-weight: bold,
      $font-family: 'Gilroy Medium',
    ),
    $subtitle-1: mat.define-typography-level($font-size: 16px,
      $line-height: 20px,
      $font-weight: bold,
      $font-family: 'Gilroy Medium',
    ),
    $body-1: mat.define-typography-level($font-size: 14px,
      $line-height: 18px,
      $font-weight: bold,
      $font-family: 'Gilroy Medium',
    ),
    $headline-1: mat.define-typography-level($font-size: 112px,
      $line-height: 1.4,
      $font-weight: bold,
      $font-family: 'Gilroy Medium',
    ),
    $headline-2: mat.define-typography-level($font-size: 56px,
      $line-height: 1.4,
      $font-weight: bold,
      $font-family: 'Gilroy Medium',
    ),
    $headline-3: mat.define-typography-level($font-size: 45px,
      $line-height: 1.4,
      $font-weight: bold,
      $font-family: 'Gilroy Medium',
    ),
    $headline-4: mat.define-typography-level($font-size: 34px,
      $line-height: 1.4,
      $font-weight: bold,
      $font-family: 'Gilroy Medium',
    ),
    $body-2: mat.define-typography-level($font-size: $base-font-size,
      $line-height: $base-line-height,
      $font-family: 'Gilroy Medium',
    ),
    $subtitle-2: mat.define-typography-level($font-size: $base-font-size,
      $line-height: $base-line-height,
      $font-weight: bold,
      $font-family: 'Gilroy Medium',
    ),
    $caption: mat.define-typography-level($font-size: 12px,
      $line-height: 1.25,
      $font-family: 'Gilroy Medium',
    ),
    $button: mat.define-typography-level($font-size: 1em,
      $line-height: 1,
      $font-family: 'Gilroy Medium',
    ),

  );

$omnia-theme: mat.define-light-theme((color: (primary: mat.define-palette($omnia-purple-palette, 700),
        accent: mat.define-palette($omnia-purple-palette, 600),
        warn: mat.define-palette(mat.$deep-orange-palette, 800),
      ),
      typography: $omnia-typography,
    ));

/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-legacy-component-themes($omnia-theme);

@include mat.all-component-themes($omnia-theme);



@layer base {
  html.omnia-theme {
    @apply text-base;
    color: $primary-color;
    font-family: 'Gilroy Medium';
    accent-color: $accent-color;
    color-scheme: light;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    b,
    strong {
      font-weight: bold;
    }

    h1 {
      @apply text-3xl;
    }

    h2 {
      @apply text-2xl;
    }

    h3 {
      @apply text-xl;
    }

    h4 {
      @apply text-base;
    }

    h5 {
      @apply text-sm;
    }

    h6 {
      @apply text-xs;
    }

    a {
      color: $accent-color;
    }

    input,
    textarea,
    select {
      outline-color: $accent-color;
    }

    .mat-icon {
      display: inline-table;
      align-items: center;
      justify-content: center;
      width: auto;
      height: auto;
    }

    .material-symbols-outlined {
      font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 20;
    }

    .mat-card,
    .mat-mdc-checkbox,
    .mat-mdc-select,
    .mat-option {
      font-family: 'Gilroy Medium';
    }

    .cdk-overlay-pane .mat-menu-panel .mat-menu-item {
      min-height: 32px !important;
      height: 34px !important;
    }

    .mat-menu-item {
      &.mat-menu-item-highlighted:not([disabled]):not(.selected) {
        background-color: lighten($light-color, 5%);

        .mat-menu-submenu-icon {
          color: $accent-color;
        }
      }

      .mat-menu-item-text {
        font-weight: 400;
      }


      &.selected {
        background-color: $light-color;

        .mat-mdc-menu-submenu-icon {
          color: $accent-color;
        }
      }

      &:hover:not([disabled]):not(.mat-menu-item-highlighted):not(.selected) {
        background-color: lighten($light-color, 5%);
      }


    }

    .mat-mdc-option:hover:not(.mat-mdc-option-disabled),
    .mat-mdc-option:focus:not(.mat-mdc-option-disabled) {
      background-color: $light-color;
      color: $accent-color;
    }

    .mat-select-panel .mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple) {
      background-color: lighten($light-color, 5%);
      color: $accent-color;
    }

    .mat-mdc-dialog-content,
    .mat-mdc-radio-button {
      color: inherit;
      font-family: inherit;
    }

    .mat-mdc-button-focus-overlay {
      background: $accent-color;
    }

    .mat-input-element {
      font-size: 1em;
      line-height: 1;
      font-family: 'Gilroy Medium';
    }
  }
}